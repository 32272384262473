import { useMemo, memo, useEffect, useState } from 'react';

import Link from 'next/link';
import { useSelector } from 'react-redux';

import { IState } from 'store';
import { IConfigProps } from 'store/modules/config';

import api from 'services/api';
import { useRouter } from 'next/router';
import { ContainerGlobal } from 'components/common/container';
import { useQuery } from 'react-query';

function NavigationThree() {
  const [marcasActive, setMarcasActive] = useState(false);
  const [categoriesSO, setCategoriesSO] = useState([]);
  const { categories, config } = useSelector<IState, IConfigProps>(
    state => state.config,
  );

  const router = useRouter();

  const ITEMS_PER_COLUMN = 5;

  useEffect(() => {
    setCategoriesSO(
      categories.find(item => {
        return item.id === Number(config?.aplicacao?.categoriaPai);
      })?.filhos || [],
    );
  }, [categories]);

  async function GetMarcas() {
    const { data } = await api.get('/marcas/menu');

    const dataSemPet = data.map(item => ({
      ...item,
      nome: item.nome
        .replaceAll('(PET)', '')
        .replaceAll('pet', '')
        .replaceAll('PET', ''),
    }));

    const columns = [];

    for (let i = 0; i < data.length; i += ITEMS_PER_COLUMN) {
      const column = dataSemPet.slice(i, i + ITEMS_PER_COLUMN);
      columns.push(column);
    }

    return {
      marcas: dataSemPet,
      colunas: columns,
    };
  }

  function GetMarcasList() {
    return useQuery([`marcas`], GetMarcas, {
      staleTime: 1000 * 60 * 30,
    });
  }

  const { data } = GetMarcasList();

  return (
    <>
      <div className="categories">
        <div className="container">
          {/* {categoriesSO?.map(nvl1 => {
            return (
              <div
                key={nvl1.descricao + nvl1.permalink}
                className="categorie-item"
              >
                <Link href={'/categoria/' + nvl1.permalink} passHref>
                  <a href="replaced">{nvl1.nome}</a>
                </Link>

                {nvl1.filhos?.map(nvl2 => {
                  console.log(nvl2.filhos)
                  return (
                    <div
                      key={nvl2.descricao + nvl2.permalink}
                      className="sub-menu"
                    >
                      <div className="categorie-item">
                        <Link href={'/categoria/' + nvl2.permalink} passHref>
                          <a href="replaced">
                            {nvl2.nome}
                            {nvl2.filhos && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512"
                              >
                                <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z" />
                              </svg>
                            )}
                          </a>
                        </Link>

                        {nvl2.filhos.map(nvl3 => {
                          return (
                            <div
                              key={nvl3.descricao + nvl3.permalink}
                              className="sub-menu"
                            >
                              <div className="categorie-item">
                                <Link
                                  href={'/categoria/' + nvl3.permalink}
                                  passHref
                                >
                                  <a href="replaced">{nvl3.nome}</a>
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })} */}

          {categoriesSO.map(nivel1 => {
            if (!nivel1.exibirMenu) {
              return;
            }

            return (
              <div
                key={nivel1.descricao + nivel1.permalink}
                className="categorie-item"
              >
                <Link href={'/categoria/' + nivel1.permalink} passHref>
                  <a href="replaced">{nivel1.nome}</a>
                </Link>
                <div className="sub-menu">
                  {nivel1.filhos?.map(nvl2 => {
                    if (!nvl2.exibirMenu) {
                      return;
                    }

                    return (
                      <div
                        key={nvl2.descricao + nvl2.permalink}
                        className="categorie-item"
                      >
                        <Link href={'/categoria/' + nvl2.permalink} passHref>
                          <a href="replaced">
                            {nvl2.nome}
                            {nvl2.filhos?.length > 0 && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512"
                              >
                                <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z" />
                              </svg>
                            )}
                          </a>
                        </Link>

                        <div className="sub-menu">
                          {nvl2.filhos.map(nvl3 => {
                            if (!nvl3.exibirMenu) {
                              return;
                            }

                            return (
                              <div
                                key={nvl3.descricao + nvl3.permalink}
                                className="categorie-item"
                              >
                                <Link
                                  href={'/categoria/' + nvl3.permalink}
                                  passHref
                                >
                                  <a href="replaced">{nvl3.nome}</a>
                                </Link>
                              </div>
                            );
                          })}
                          {nvl2.filhos?.length > 0 && (
                            <Link
                              href={'/categoria/' + nvl2.permalink}
                              passHref
                            >
                              <a href="replaced">Ver mais</a>
                            </Link>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  {nivel1.filhos?.length > 0 && (
                    <Link href={'/categoria/' + nivel1.permalink} passHref>
                      <a href="replaced">Ver mais</a>
                    </Link>
                  )}
                </div>
              </div>
            );
          })}

          {/* <div className="categorie-item marcas">
            <a onMouseLeave={() => setMarcasActive(false)} onMouseEnter={() => setMarcasActive(true)}>Marcas</a>

            <div className="sub-menu onInMobile">
              {data?.marcas?.map(marca => {
                return (
                  <div
                    key={marca.nome + marca.permalink}
                    className="categorie-item"
                  >
                    <Link href={'/marca/' + marca.permalink} passHref>
                      <a href="replaced">{marca.nome}</a>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div> */}
        </div>
      </div>

      {/* <div
        className={`marcas-container  ${marcasActive && 'active'}`}
        onMouseEnter={() => setMarcasActive(true)}
        onMouseLeave={() => setMarcasActive(false)}
      >
        <ContainerGlobal>
          <div className="marcas-columns">
            {data?.colunas?.length > 0 &&
              data?.colunas?.map((column, columnIndex) => {
                return (
                  <div
                    className="marcas-column"
                    key={`column-${columnIndex + Math.random()}`}
                  >
                    {column.map(marca => {
                      return (
                        <div key={marca.id + 'marca' + Math.random()}>
                          <Link href={'/marca/' + marca.permalink} passHref>
                            <a href="replaced">{marca.nome}</a>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </ContainerGlobal>
      </div> */}
    </>
  );
}

export const MemoizedNavigationThree = NavigationThree;
