import styled from 'styled-components';

import { colors } from 'styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .user {
    margin-bottom: 3.6rem;
    color: ${colors.text_black};

    p {
      color: ${colors.text_gray};
      font-size: 1.4rem;
      margin-top: 0.4rem;
    }
  }

  .menu {
    display: flex;
    flex-direction: column;

    div,
    .navigate {
      width: 100%;
      /* background: ${colors.gray_light}; */
      padding: 1.2rem;
      color: ${colors.green_primary};

      &.menu-navigation {
        display: flex;
        flex-direction: column;

        span {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 700;

          svg {
            width: 2.4rem;
            height: 2.4rem;
          }
        }
      }
    }
  }

  .footer {
    width: 100%;
    margin-top: auto;

    display: flex;
    align-items: center;
    flex-direction: column;

    div {
      max-width: 15rem;
      margin-bottom: 2.4rem;

      img {
        width: 100%;
      }
    }

    strong {
      margin-bottom: 1.2rem;
      color: ${colors.green_primary};
    }

    a {
      font-size: 1.4rem;
      color: ${colors.text_gray};

      & + a {
        margin-top: 1.2rem;
      }
    }
  }
`;
