import React from 'react';

import { FiAlertCircle } from 'react-icons/fi';

import { Error } from './styles';

interface IInputErrorProps {
  title: string;
}

const InputError: React.FC<IInputErrorProps> = ({ title }) => {
  return (
    <Error title={title}>
      <FiAlertCircle size={20} />
    </Error>
  );
};

export default InputError;
