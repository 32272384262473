import styled from 'styled-components';
import { colors } from 'styles';

export const Search = styled.form`
  display: flex;
  align-items: center;
  width: 100%;

  input,
  button {
    min-height: 40px;
  }

  input {
    border: 1px solid #fff;
    border-right: unset;
    background-color: #f2f2f2;
    border-radius: 4px 0 0 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    max-width: calc(100% - 40px);
    width: 100%;
    font-weight: 700;
    padding: 10px;
    color: #787878;
  }

  button {
    width: 40px;
    border: 1px solid #fff;
    background-color: ${colors.green_dark};
    border-radius: 0 4px 4px 0;

    svg {
      stroke: #fff;

      circle {
        fill: transparent;
      }
    }
  }
`;
