import styled from 'styled-components';

import { colors, metrics } from 'styles';

type ContainerWrapperProps = {
  backgroundColor: string;
};

export const Header = styled.div<ContainerWrapperProps>`
  a,
  button {
    transition: 0.1s;
  }

  .sair {
    background-color: transparent;
    border: 0;
  }

  a:hover,
  button:hover {
    transition: 0.1s;
  }

  .show-mobile {
    display: none !important;
  }

  .container {
    max-width: 1400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
  }

  header {
    z-index: 100;
    position: relative;
    background-color: ${colors.green_dark};

    * {
      color: #fff;
    }

    .top-image {
      padding: 10px 0;

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    #heightSwap {
      display: none;
    }

    .toggle-categories {
      display: none;
      width: 42px;
      height: 42px;
      font-size: 13px;
      font-weight: 500;
      border-radius: 4px;
      border: 1px solid #ebebeb;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: #fff;

      .block-bar {
        width: 21px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        div {
          width: 100%;
          height: 2px;
          background-color: var(--grey-800);
        }
      }
    }

    .top {
      padding: 7px 0;

      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: inherit;
      }

      a,
      p {
        color: #fff;
        font-weight: 700;
      }

      ul {
        display: flex;
        align-items: center;

        li {
          margin-right: 10px;
          border-right: 1px solid var(--grey-800);
          padding-right: 10px;

          a {
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 12px;

            i {
              height: 10px;
              width: 12px;

              svg {
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
                fill: currentColor;
              }
            }

            span {
              width: calc(100% - 17px);
            }
          }

          a:hover {
            text-decoration: underline;
          }
        }

        li:last-child {
          border-right: 0;
          margin-right: 0;
          padding-right: 0;
        }
      }

      p {
        font-size: 13px;
        line-height: 1.5;
        margin-bottom: 0;
        text-align: center;
        font-weight: 400;

        span {
          display: block;
          color: #fff;
          font-weight: 700;
        }

        a:hover {
          color: var(--primary-color);
        }
      }
    }

    .middle {
      padding: 0 0 12px;
      border-bottom: 1px solid var(--grey-100);

      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
      }

      .left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 30px;
      }

      .right {
        display: flex;
        align-items: center;
      }
    }

    .bottom {
      border-bottom: 1px solid var(--grey-100);
    }

    .logo {
      max-width: 225px;
      width: 100%;

      img {
        width: 100%;
      }
    }

    .search-mobile {
      display: none;
    }

    .search {
      display: flex;
      height: 40px;
      width: clamp(440px, 33vw, 635px);

      input {
        background-color: var(--grey-100);
        font-size: 14px;
        border-radius: 4px 0 0 4px;
        padding: 10px;
        font-size: 14px;
        width: calc(100% - 42px);
        height: 42px;
        color: var(--primary-color) !important;
      }

      input::placeholder {
        color: #ccc;
        font-weight: 700;
      }

      input:focus {
        outline: transparent;
      }

      button {
        background-color: var(--primary-color);
        border: 1px solid #fff !important;
        border-radius: 0 4px 4px 0;
        width: 42px;

        svg {
          width: 16px;
          height: auto;
          fill: #fff;
        }
      }

      button:hover {
        opacity: 0.9;
      }
    }

    .action {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;

      i {
        height: 19px;
        width: 19px;

        svg {
          fill: #fff;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }

      span {
        font-size: 14px;
      }

      a {
        font-size: 12px;
      }

      a,
      span {
        color: #fff;
        font-weight: 700;
        text-decoration: unset;
      }

      a:hover {
        color: var(--primary-color);
      }

      .action--links {
        display: flex;
        align-items: center;
      }

      .separator {
        height: 11px;
        margin-left: 3px;
        width: 1px;
        background-color: #636666;
        margin-right: 3px;
      }
    }

    .action + .action {
      margin-left: 10px;
      padding-left: 10px;
      border-left: 1px solid rgba(100, 100, 100, 0.15);
    }

    .categories {
      background: #fff;
      var(--primary-color);
      border-bottom: 1px solid #f2f2f2;

      .container {
        display: flex;

        > .categorie-item {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }

      a {
        color: var(--primary-color);
        font-weight: 700;
        font-size: 14px;
        text-decoration: unset;
        height: 25px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      a:hover {
        text-decoration: underline;
      }

      .categorie-item {
        position: relative;

        > .sub-menu {
          display: none;
          content-visibility: hidden;
          position: absolute;
          bottom: 0;
          left: 0;
          transform: translateY(100%);
          min-width: 260px;
          width: 100px;
          background-color: #fff;

          .sub-menu {
            transform: translateX(100%) translateY(calc(100% - 25px));
          }
        }

        .sub-menu {
          a {
            padding-right: 30px;
            color: var(--primary-color);
            justify-content: flex-start;

            svg {
              position: absolute;
              right: 5px;
              width: 6px;
              height: auto;
              fill: var(--primary-color);
            }
          }

          a:hover {
            background-color: var(--primary-color);
            color: #fff;
            text-decoration: unset;

            svg {
              fill: #fff;
            }
          }
        }
      }

      .categorie-item:hover {
        > .sub-menu {
          content-visibility: visible;
          display: block;
        }
      }
    }

    .categories.active {
      display: block;
    }
  }

  header + * {
    position: relative;
    z-index: 1;
  }

  header.fixed {
    z-index: 100;

    @media only screen and (min-width: 992px) {
      .container {
        max-width: 1170px;
        width: 100%;
      }
    }

    #heightSwap {
      display: block;
    }

    .toggle-categories {
      display: flex;
    }

    .scroll-div {
      position: fixed;
      background-color: var(--primary-color);
      width: 100%;
      top: 0;
      left: 0;
      box-shadow: 0 0 3px rgba(0, 0, 0, 30%);
    }

    .search {
      width: clamp(440px, 33vw, 450px);

      input {
        padding: 5px;
        font-size: 13px;
      }
    }

    .middle {
      padding: 20px 0;
    }

    .logo {
      max-width: 200px;
    }

    .top {
      display: none;
    }

    .categories {
      display: none;
    }

    .categories.active {
      display: flex;
    }
  }

  @media only screen and (max-width: 1200px) {
    header {
      .logo {
        width: 200px;
      }

      .top {
        display: none;
      }

      .toggle-categories {
        display: flex;
      }

      .categories {
        display: none !important;
      }

      .middle {
        padding: 30px 0;

        .right {
          .profile {
            display: none;
          }
        }
      }
    }

    header.fixed .categories.active {
      display: none;
    }
  }

  @media only screen and (max-width: 1024px) {
    header {
      .logo {
       width: 190px;
      }

      .toggle-categories {
        width: 44px;
        height: 40px;

        span {
          display: none;
        }

        .block-bar {
          width: 23px;
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    #header {
      display: none;
    }
  }

  @media only screen and (max-width: 768px) {
    .show-mobile {
      display: block !important;
    }

    header {
      .toggle-categories {
        width: 45px;
        height: 40px;

        .block-bar {
          width: 80%;
          display: flex;
          flex-direction: column;
          gap: 5px;

          div {
            background-color: rgba(0, 0, 0, 0.8);
            height: 2px;
          }
        }

        span {
          display: none;
        }
      }

      .search {
        display: none;
      }

      .search-mobile {
        display: block;
        padding: 0 15px 15px;

        .search {
          width: 100%;
          display: flex;
        }
      }

      .logo {
        width: 174px;
        order: 1;
      }

      .middle {
        padding: 20px 0;
        border-bottom: 0;

        .right {
          display: none;
        }

        .left {
          justify-content: space-between;
          width: 100%;

          .show-mobile {
            order: 3;
          }
        }
      }

      .cart {
        span {
          display: none;
        }
      }

      .categories.active {
        display: none;
      }
    }
  }

  #menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 102;
    height: 100vh;
    display: flex;
    transform: translateX(-100%);
    transition: 0.2s;

    .overlay {
      background-color: rgba(0, 0, 0, 0.4);
      height: inherit;
      width: 30%;
      cursor: pointer;
      opacity: 0;
      transition: 0.2s;
    }

    .content {
      padding: 17px 0;
      width: 70%;
      background-color: #fff;
      height: inherit;
    }

    .text {
      margin-bottom: 15px;
      padding: 0 10px;

      .nome-user {
        font-weight: bold;
        line-height: 1em;
        font-size: 16px;
        margin-top: 5px;
        margin-bottom: 5px;
      }

      p {
        color: #3c3c3c;
        margin-bottom: 0;
        font-size: 12px;

        a {
          color: inherit;
          text-decoration: unset;
        }
      }
    }

    .tab {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        border: 0;
        background-color: transparent;

        svg {
          width: 9px;
          height: auto;
        }
      }
    }

    .grey-area {
      background-color: #f0f0f0;
      padding: 15px;

      h4,
      a {
        color: #252525;
        font-size: 16px;
        margin: 0;
        text-decoration: unset;
        font-weight: 600;
      }
    }

    .informations {
      text-align: center;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      .logo {
        width: 270px;
        margin-bottom: 20px;
      }

      h3 {
        font-weight: bold;
        text-transform: uppercase;
        color: #444;
        margin: 10px 0 6px;
        font-size: 15px;
      }

      a {
        color: #3c3c3c;
        text-decoration: unset;
      }
    }
  }

  #menu-mobile.active {
    transform: translateX(0%);
    transition: 0.2s;

    .overlay {
      opacity: 1;
      transition: 0.5s;
    }
  }

  @media only screen and (max-width: 768px) {
    #menu-mobile {
      .informations {
        margin-top: 25px;

        .logo {
          width: 185px;
          margin-bottom: 10px;
        }

        h3 {
          margin: 5px 0 4px;
          font-size: 14px;
        }

        a {
          font-size: 13px;
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    #menu-mobile {
      .informations {
        .logo {
          width: 145px;
          margin-bottom: 5px;
        }

        h3 {
          margin: 3px 0 3px;
          font-size: 13px;
        }

        a {
          font-size: 12px;
        }
      }
    }
  }

  .marcas-container {
    display: none;
    background-color: var(--primary-color);
    padding: 20px;
    position: absolute;
    left: 0;
    z-index: 10;
    width: 100%;
    border-bottom: 1px solid var(--primary-color);

    h3 {
      font-size: 2.2rem;
      color: #fff !important;
      margin-bottom: 10px;
    }
  }

  .marcas-columns {
    display: flex;
    flex-wrap: wrap;

    a {
      height: unset;
      padding: unset;
      display: inline-block;
      color: #fff !important;
      margin: 6px 25px;
      font-size: 12.5px;
      font-weight: 700;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .marcas-container.active {
    display: block;
  }

  .onInMobile {
      display: none !important;
    }

  @media only screen and (max-width: 1200px) {
    .marcas-container {
      display: none !important;
    }

    .onInMobile {
      display: block !important;
    }
  }
`;
