/* eslint-disable react/self-closing-comp */
import { useCallback, useEffect } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import BannerPix from 'assets/banner-pix-brinde-1.png';
import { NextImage } from 'components/next-image';
import { useMiniCart } from 'hooks';
import { IState } from 'store';
import { IConfigProps } from 'store/modules/config';
import { ICartProps, cleanCheckout } from 'store/modules/cart';
import { IUserProps, signOut } from 'store/modules/profile';
import { objectIsEmpty } from 'utils/objectEmpty';

import MiniCart from '../MiniCart';
import HeaderMobile from './HeaderMobile';
import { MemoizedNavigationThree } from './NavigationThree';
import { NavigationThreeMobile } from './NavigationThreeMobile';
import { MemoizedRegion } from './Region';
import Search from './Search';
import * as S from './styles';

interface IHeaderComponentProps {
  backgroundColor?: string;
}

export function HeadeComponent({ backgroundColor }: IHeaderComponentProps) {
  useEffect(() => {
    if (process.browser) {
      const header = document.getElementById('header');
      // const menuMobile = document.getElementById('menu-mobile');
      // const menuMobileOverlay = document.querySelector('#menu-mobile .overlay');
      const headerHeight = header.offsetHeight;

      const buttonToggleCategories =
        document.getElementsByClassName('toggle-categories');
      const categories = document.getElementsByClassName('categories');

      document.getElementsByTagName('body')[0].onscroll = ev => {
        if ((ev.composedPath()[1] as any).pageYOffset > headerHeight) {
          const swap = document.getElementById('heightSwap');

          if (swap) {
            swap.style.height = `${headerHeight}px`;
            header.classList.add('fixed');
          }

          return;
        }

        header.classList.remove('fixed');
      };
      (buttonToggleCategories[0] as any).onclick = function () {
        categories[0].classList.toggle('active');
      };
    }
  }, []);

  const router = useRouter();
  const dispatch = useDispatch();
  const { dropMiniCart, dropped } = useMiniCart();
  const cart = useSelector<IState, ICartProps>(state => state.cart.cart);
  const context = useSelector<IState, IConfigProps>(state => state.config);

  const user = useSelector<IState, IUserProps>(state => state.profile.user);

  const handleNavigateToLogin = useCallback(() => {
    if (objectIsEmpty(user)) {
      router.push('/identificacao');
    } else {
      router.push('/central-do-cliente/pedidos');
    }
  }, [router, user]);

  const handleSignOut = useCallback(() => {
    dispatch(signOut({}));
    dispatch(cleanCheckout());
  }, [dispatch]);

  // useEffect(() => {
  //   if(!context.config?.aparencia?.logo)
  //   loadConfigApplicationRequest()
  // }, [router.pathname]);

  return (
    <>
      {dropped && <MiniCart onClose={dropMiniCart} />}

      <S.Header backgroundColor={backgroundColor}>
        <HeaderMobile />

        <header id="header">
          {/* <div >
            <div className="content">
              <div className="text">
                <h3 className="nome-user">Olá, visitante!</h3>
                <p>
                  <a href="/">Entre</a> ou <a href="/">cadastre-se aqui</a>
                </p>
              </div>

              <NavigationThreeMobile />

              <div className="informations">
                <h3>FALE CONOSCO:</h3>
                <a href="/fale-conosco">Atendimento por E-mail</a>
                <a href="/">Chat Online</a>
              </div>
            </div>

            <div className="overlay"></div>
          </div> */}

          <div id="heightSwap"></div>

          <div className="scroll-div">
            <div className="top">
              <div className="container">
                <div className="left">
                  <ul>
                    <li>
                      <a href="/fale-conosco">Atendimento ao cliente</a>
                    </li>

                    <li>
                      {/* REDIRECIONAR PARA DENTRO DA PLATAFORMA */}
                      <a href="/central-do-cliente/pedidos">
                        <i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                          >
                            <path d="M368 0C394.5 0 416 21.49 416 48V96H466.7C483.7 96 499.1 102.7 512 114.7L589.3 192C601.3 204 608 220.3 608 237.3V352C625.7 352 640 366.3 640 384C640 401.7 625.7 416 608 416H576C576 469 533 512 480 512C426.1 512 384 469 384 416H256C256 469 213 512 160 512C106.1 512 64 469 64 416H48C21.49 416 0 394.5 0 368V48C0 21.49 21.49 0 48 0H368zM416 160V256H544V237.3L466.7 160H416zM160 368C133.5 368 112 389.5 112 416C112 442.5 133.5 464 160 464C186.5 464 208 442.5 208 416C208 389.5 186.5 368 160 368zM480 464C506.5 464 528 442.5 528 416C528 389.5 506.5 368 480 368C453.5 368 432 389.5 432 416C432 442.5 453.5 464 480 464z" />
                          </svg>
                        </i>

                        <span>Acompanhe seu pedido</span>
                      </a>
                    </li>
                  </ul>
                </div>

                <MemoizedRegion />
              </div>
            </div>

            <div className="middle">
              <div className="container">
                <div className="left">
                  <Link href="/" passHref>
                    <a href="replaced" className="logo">
                      {context.config.aparencia?.logo && (
                        <img
                          src={context.config.aparencia?.logo}
                          alt="Logo Ultrafarma"
                        />
                      )}
                    </a>
                  </Link>

                  <button
                    type="button"
                    aria-label="abrir menu"
                    className="toggle-categories uppercase"
                  >
                    <div className="block-bar">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </button>

                  <div className="search">
                    <Search />
                  </div>

                  <div className="show-mobile">
                    <div className="action cart" onClick={dropMiniCart}>
                      <i>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                        >
                          <path d="M171.7 191.1H404.3L322.7 35.07C316.6 23.31 321.2 8.821 332.9 2.706C344.7-3.409 359.2 1.167 365.3 12.93L458.4 191.1H544C561.7 191.1 576 206.3 576 223.1C576 241.7 561.7 255.1 544 255.1L492.1 463.5C484.1 492 459.4 512 430 512H145.1C116.6 512 91 492 83.88 463.5L32 255.1C14.33 255.1 0 241.7 0 223.1C0 206.3 14.33 191.1 32 191.1H117.6L210.7 12.93C216.8 1.167 231.3-3.409 243.1 2.706C254.8 8.821 259.4 23.31 253.3 35.07L171.7 191.1zM191.1 303.1C191.1 295.1 184.8 287.1 175.1 287.1C167.2 287.1 159.1 295.1 159.1 303.1V399.1C159.1 408.8 167.2 415.1 175.1 415.1C184.8 415.1 191.1 408.8 191.1 399.1V303.1zM271.1 303.1V399.1C271.1 408.8 279.2 415.1 287.1 415.1C296.8 415.1 304 408.8 304 399.1V303.1C304 295.1 296.8 287.1 287.1 287.1C279.2 287.1 271.1 295.1 271.1 303.1zM416 303.1C416 295.1 408.8 287.1 400 287.1C391.2 287.1 384 295.1 384 303.1V399.1C384 408.8 391.2 415.1 400 415.1C408.8 415.1 416 408.8 416 399.1V303.1z" />
                        </svg>
                      </i>

                      <div>
                        <span>{cart.subtotalTexto || 'R$0,00'}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="right">
                  <div className="action profile">
                    <i>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z" />
                      </svg>
                    </i>

                    {objectIsEmpty(user) ? (
                      <div>
                        <span>Olá, visitante!</span>

                        <div className="action--links">
                          <a href="/cadastro">Cadastre-se</a>
                          <div className="separator"></div>
                          <a href="/identificacao">Login</a>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p>Olá, {user.nomeCompleto}</p>
                        <p>
                          <button
                            type="button"
                            onClick={handleNavigateToLogin}
                            className="sair"
                          >
                            Acessar painel
                          </button>{' '}
                          |{' '}
                          {!objectIsEmpty(user) && (
                            <button
                              type="button"
                              onClick={handleSignOut}
                              className="sair"
                            >
                              Sair
                            </button>
                          )}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="action" onClick={dropMiniCart}>
                    <i>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path d="M171.7 191.1H404.3L322.7 35.07C316.6 23.31 321.2 8.821 332.9 2.706C344.7-3.409 359.2 1.167 365.3 12.93L458.4 191.1H544C561.7 191.1 576 206.3 576 223.1C576 241.7 561.7 255.1 544 255.1L492.1 463.5C484.1 492 459.4 512 430 512H145.1C116.6 512 91 492 83.88 463.5L32 255.1C14.33 255.1 0 241.7 0 223.1C0 206.3 14.33 191.1 32 191.1H117.6L210.7 12.93C216.8 1.167 231.3-3.409 243.1 2.706C254.8 8.821 259.4 23.31 253.3 35.07L171.7 191.1zM191.1 303.1C191.1 295.1 184.8 287.1 175.1 287.1C167.2 287.1 159.1 295.1 159.1 303.1V399.1C159.1 408.8 167.2 415.1 175.1 415.1C184.8 415.1 191.1 408.8 191.1 399.1V303.1zM271.1 303.1V399.1C271.1 408.8 279.2 415.1 287.1 415.1C296.8 415.1 304 408.8 304 399.1V303.1C304 295.1 296.8 287.1 287.1 287.1C279.2 287.1 271.1 295.1 271.1 303.1zM416 303.1C416 295.1 408.8 287.1 400 287.1C391.2 287.1 384 295.1 384 303.1V399.1C384 408.8 391.2 415.1 400 415.1C408.8 415.1 416 408.8 416 399.1V303.1z" />
                      </svg>
                    </i>
                    <div>
                      <p>{cart.subtotalTexto || 'R$0,00'}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <MemoizedNavigationThree />

            <div className="search-mobile">
              <div className="search">
                <Search />
              </div>
            </div>
          </div>
        </header>
      </S.Header>
    </>
  );
}
