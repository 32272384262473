import React, { useEffect, useState } from 'react';
import api from 'services/api';

import { INavigationCategoryProps } from 'store/modules/config';

import NavigationItem from './NavigationItem';
import { Container } from './styles';

interface IDepartmentNavigationProps {
  onClose(): void;
  categories: INavigationCategoryProps[];
  nivel: number;
  showMarcas?: boolean
}

const DepartmentNavigation: React.FC<IDepartmentNavigationProps> = ({
  onClose,
  categories,
  nivel,
  showMarcas
}) => {
  const [marcas, setMarcas] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/marcas/menu');

      setMarcas(data);
    })();
  }, []);

  return (
    <Container className={`nivel-${nivel}`}>
      {!showMarcas && categories.map(category => (
        <NavigationItem
          onClose={onClose}
          category={category}
          nivel={1}
          key={category.id}
        />
      ))}

{/* {!showMarcas &&
        categories.map(nivel1 => {
          return (
            <div
              key={nivel1.descricao + nivel1.permalink}
              className="categorie-item"
            >
              <Link href={'/categoria/' + nivel1.permalink} passHref>
                <a href="replaced">{nivel1.nome}</a>
              </Link>
              
              <div className="sub-menu">
                {nivel1.filhos?.map(nvl2 => {
                  return (
                    <div
                      key={nvl2.descricao + nvl2.permalink}
                      className="categorie-item"
                    >
                      <Link href={'/categoria/' + nvl2.permalink} passHref>
                        <a href="replaced">
                          {nvl2.nome}
                          {nvl2.filhos && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                            >
                              <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z" />
                            </svg>
                          )}
                        </a>
                      </Link>
                      <div className="sub-menu">
                        {nvl2.filhos.map(nvl3 => {
                          return (
                            <div
                              key={nvl3.descricao + nvl3.permalink}
                              className="categorie-item"
                            >
                              <Link
                                href={'/categoria/' + nvl3.permalink}
                                passHref
                              >
                                <a href="replaced">{nvl3.nome}</a>
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })} */}


      {showMarcas && marcas.map(marca => (
        <NavigationItem
          onClose={onClose}
          category={marca}
          nivel={nivel}
          key={marca.id}
          marca
        />
      ))}
    </Container>
  );
};

export default DepartmentNavigation;
