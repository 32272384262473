import Image from 'next/image';
import styled from 'styled-components';

export const Container = styled(Image)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 70%;
`;
