import styled from 'styled-components';

import { colors } from 'styles';

export const Container = styled.div`
  display: flex;
  width: 100%;

  padding: 0 2.4rem 1.2rem;
  margin-bottom: 1.2rem;
  border-bottom: 1px solid ${colors.gray};
  height: max-content;

  .img-container {
    width: 10rem;
    background: ${colors.background_card};
    border-radius: 0.8rem;
    position: relative;

    img {
      width: 100%;
      border-radius: 0.8rem;
    }
  }

  .description {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    h5 {
      color: ${colors.text_black};
    }

    span {
      border: 1px solid ${colors.gray};
      padding: 0.8rem 1.2rem;
      border-radius: 10px;
      align-self: flex-end;
      color: ${colors.text_gray};
    }
  }
`;
