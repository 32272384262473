import styled from 'styled-components';

import { colors } from 'styles';

export const Container = styled.div`
  padding: 18px 0;

  .container-global {
    display: flex;
    gap: 15px;
    justify-content: space-between;
  }

  .mail {
    display: flex;
    align-items: center;
    gap: 7px;
    max-width: 250px;
    width: 100%;
    font-weight: 600;

    svg {
      width: 27px;
      height: auto;
    }

    p {
      font-size: 13px;
      width: calc(100% - 67px);
    }
  }

  form {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 900px;

    .input-container {
      max-width: 500px;
      width: 100%;
    }

    .button-component {
      max-width: 200px;
      min-height: 38px;
    }
  }

  .medias {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
      max-width: 100px;
      font-weight: 500;
    }
  }

  @media only screen and (max-width: 1400px) {
    form {
      max-width: 600px;
    }
  }

  @media only screen and (max-width: 1200px) {
    form {
      max-width: 500px;
    }

    .medias {
      p {
        font-size: 14px;
      }
    }

    .mail {
      max-width: 228px;

      p {
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .medias {
      display: none;
    }
  }

  @media only screen and (max-width: 900px) {
    form {
      max-width: 400px;
    }

    .mail {
      max-width: 215px;
      svg {
        width: 23px;
      }

      p {
        font-size: 11px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .container-global {
      flex-direction: column;
      align-items: center;
    }

    form {
      .button-component {
        min-height: 32px;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .container-global {
      flex-direction: column;
      align-items: center;
    }

    form {
      flex-direction: column;
      max-width: 100%;

      .button-component {
        max-width: 100%;
        min-height: 32px;
      }
    }
  }
`;
