import { animated } from 'react-spring';
import styled, { css } from 'styled-components';

import { colors, metrics } from 'styles';

interface IButtonCloseProps {
  left: boolean;
}

export const ContainerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  height: 100vh;
  max-width: 90vw;
  z-index: 99;

  background: transparent;
`;

export const ContainerLeftSide = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;

  width: 40rem;
  max-width: 80vw;
  height: 100%;

  background: ${colors.background};
  border: 1px solid ${colors.gray};

  z-index: 999;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 10px 15px #414141;
  display: flex;
  flex-direction: columns;

  .button-close {
    position: relative;
  }
`;

export const Content = styled.div`
  flex: 1;
  margin-bottom: auto;
  padding: 2.4rem;

  overflow-y: scroll;
  ::-webkit-scrollbar-track {
    background-color: #f4f4f4;
  }

  scrollbar-track-color: #f4f4f4;
  ::-webkit-scrollbar {
    width: 0.6rem;
    background: #f4f4f4;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.green_primary};
    border-radius: 1rem;
  }

  scrollbar-base-color: ${colors.green_primary};
`;

export const ButtonClose = styled.span<IButtonCloseProps>`
  position: absolute;
  top: 2.4rem;
  right: -2.4rem;
  ${props =>
    !props.left &&
    css`
      left: -2.4rem;
      right: 0;
    `}
  box-shadow: ${metrics.box_shadow};

  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  background: ${colors.green_primary};

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${colors.white};
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export const ContainerRightSide = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;

  width: 40rem;
  max-width: 80vw;
  height: 100%;

  background: ${colors.background};
  border: 1px solid ${colors.gray};

  z-index: 999;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 10px 15px #414141;

  display: flex;
  flex-direction: columns;

  .button-close {
    position: relative;
  }
`;
