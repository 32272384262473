import React, { useCallback, useState, MouseEvent, useEffect } from 'react';

import { FiX } from 'react-icons/fi';
import { useSpring } from 'react-spring';

import {
  ContainerWrapper,
  ContainerLeftSide,
  Content,
  ButtonClose,
  ContainerRightSide,
} from './styles';

interface ISideMenuProps {
  onClose(): void;
  left?: boolean;
}

const SideMenu: React.FC<ISideMenuProps> = ({
  onClose,
  left = false,
  children,
}) => {
  const [reverse, setReverse] = useState(false);

  const sideMenuRight = useSpring({
    from: { right: '-120%', opacity: 0 },
    to: { right: '0%', opacity: 1 },
    reset: reverse,
    reverse,
  });

  const sideMenuLeft = useSpring({
    from: { left: '-120%', opacity: 0 },
    to: { left: '0%', opacity: 1 },
    reset: reverse,
    reverse,
  });

  const handleToggle = useCallback(() => {
    setReverse(state => !state);

    setTimeout(() => {
      onClose();
    }, 500);
  }, [onClose]);

  const handleControlPropagation = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    },
    [],
  );

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100vh';

    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'initial';
    };
  }, []);

  return (
    <ContainerWrapper onClick={onClose} role="presentation">
      {left ? (
        <ContainerLeftSide
          style={sideMenuLeft}
          onClick={handleControlPropagation}
        >
          <div className="button-close">
            <ButtonClose left={left} onClick={handleToggle}>
              <FiX />
            </ButtonClose>
          </div>
          <Content>{children}</Content>
        </ContainerLeftSide>
      ) : (
        <ContainerRightSide
          style={sideMenuRight}
          onClick={handleControlPropagation}
        >
          <div className="button-close">
            <ButtonClose left={left} onClick={handleToggle}>
              <FiX />
            </ButtonClose>
          </div>
          <Content>{children}</Content>
        </ContainerRightSide>
      )}
    </ContainerWrapper>
  );
};

export default SideMenu;
