import React, { useCallback, useState } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';
import { RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import imgLogo from 'assets/logo.png';
import { IState } from 'store';
import {
  IConfigApplicationProps,
  INavigationCategoryProps,
} from 'store/modules/config';
import { signOut, IUserProps } from 'store/modules/profile';
import { objectIsEmpty } from 'utils/objectEmpty';

import DepartmentNavigation from './DepartmentNavigation';
import { Container } from './styles';

interface IHeaderMenuProps {
  categories: INavigationCategoryProps[];
  onClose(): void;
}

const HeaderMenu: React.FC<IHeaderMenuProps> = ({ categories, onClose }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const user = useSelector<IState, IUserProps>(state => state.profile.user);
  const { aparencia, loja } = useSelector<IState, IConfigApplicationProps>(
    state => state.config.config,
  );

  const [toggleDepartment, setToggleDepartment] = useState(false);

  const handleToggleDepartment = useCallback(() => {
    setToggleDepartment(state => !state);
  }, []);

  const [toggleMarcas, setToggleMarcas] = useState(false);

  const handleToggleMarcas = useCallback(() => {
    setToggleMarcas(state => !state);
  }, []);

  const handleNavigateToLogin = useCallback(() => {
    if (objectIsEmpty(user)) {
      router.push('/identificacao');
    } else {
      router.push('/central-do-cliente/pedidos');
    }
    onClose();
  }, [router, user, onClose]);

  const handleSignOut = useCallback(() => {
    dispatch(signOut({}));
  }, [dispatch]);

  return (
    <Container>
      <div className="user" onClick={handleNavigateToLogin}>
        <h4>Olá, {!objectIsEmpty(user) ? user.nomeCompleto : 'visitante!'}</h4>
        {!objectIsEmpty(user) ? (
          <p>Acessar painel do usuário</p>
        ) : (
          <p>Cadastre-se | Login</p>
        )}
      </div>

      <div className="menu">
        <div className="menu-navigation">
          <DepartmentNavigation
            onClose={onClose}
            categories={categories}
            nivel={1}
          />
        </div>

        {/* <div className="menu-navigation">
          <span>
            Marcas
            {toggleMarcas ? (
              <RiArrowDownSLine onClick={handleToggleMarcas} />
            ) : (
              <RiArrowRightSLine onClick={handleToggleMarcas} />
            )}
          </span>
          {toggleMarcas && (
            <DepartmentNavigation
              onClose={onClose}
              categories={categories}
              nivel={1}
              showMarcas
            />
          )}
        </div> */}

        {!objectIsEmpty(user) && (
          <>
            <div
              onClick={() => {
                router.push('/central-do-cliente/pedidos');
                onClose();
              }}
            >
              Meus Pedidos
            </div>
            <div
              onClick={() => {
                router.push('/central-do-cliente');
                onClose();
              }}
            >
              Alterar Cadastro
            </div>
            <a
              href="https://catalogo.sidneyoliveira.com.br/"
              target="_blank"
              rel="noopener noreferrer"
              className="navigate"
            >
              Área do Consultor
            </a>
          </>
        )}
        <div onClick={handleSignOut}>Sair</div>
      </div>

      <div className="footer">
        <div
          onClick={() => {
            router.push('/');
            onClose();
          }}
          style={{ width: '100%' }}
        >
          <img
            loading="lazy"
            src={aparencia?.logo || imgLogo.src}
            alt={loja?.nome || ''}
          />
        </div>

        <strong>FALE CONOSCO</strong>
        <a
          href="https://www.ultrafarma.com.br/fale-conosco"
          target="_blank"
          rel="noopener noreferrer"
        >
          Atendimento por E-mail
        </a>
        <a href="/">Chat Online</a>
      </div>
    </Container>
  );
};

export default HeaderMenu;
