import Image from 'next/image';

import emptyImage from 'assets/produto-sem-imagem.jpg';
import { GiftTag } from 'components/common/GiftTag';
import { IGiftProductProps } from 'store/modules/cart';

import { Container } from './styles';

interface IProductGiftMiniCartProps {
  item: IGiftProductProps;
}

export function ProductGiftMiniCart({ item }: IProductGiftMiniCartProps) {
  return (
    <Container>
      <div className="img-container">
        <Image
          loading="lazy"
          src={item.imagem || emptyImage}
          alt={item.nome}
          width={100}
          height={100}
        />
        <GiftTag />
      </div>

      <div className="description">
        <h5>{item.nome}</h5>

        <span>{item.quantidade}</span>
      </div>
    </Container>
  );
}
