import styled from 'styled-components';

import { colors } from 'styles';

import Tooltip from '../Tooltip';

export const Error = styled(Tooltip)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  margin-left: 1.6rem;

  svg {
    margin: 0;
    color: ${colors.red_error};
    width: 2rem;
    height: 2rem;
  }

  span {
    background: ${colors.red_error};
    color: ${colors.text_white};

    &::before {
      border-color: ${colors.red_error} transparent;
    }
  }
`;
