import React, { useCallback, useState } from 'react';

import { useRouter } from 'next/router';
import { RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri';

import { INavigationCategoryProps } from 'store/modules/config';

import DepartmentNavigation from '..';

interface INavigationItemProps {
  onClose(): void;
  category: INavigationCategoryProps;
  nivel: number;
  marca?: boolean;
}

const NavigationItem: React.FC<INavigationItemProps> = ({
  onClose,
  category,
  nivel,
  marca,
}) => {
  const router = useRouter();
  const [toggleDepartment, setToggleDepartment] = useState(false);

  const handleToggleDepartment = useCallback(() => {
    setToggleDepartment(state => !state);
  }, []);

  const handleNavigate = useCallback(
    (permalink: string) => {
      router.push(marca ? '/marca/' + permalink : `/categoria/${permalink}`);
      onClose();
    },
    [onClose, router],
  );
  return (
    <li
      key={category.id}
      className={`navigation-item ${marca ? 'marca' : 'departamento'}`}
    >
      <div>
        <span onClick={() => handleNavigate(category.permalink)}>
          {category.nome}
        </span>

        {!!category.filhos?.length &&
          (toggleDepartment ? (
            <RiArrowDownSLine onClick={handleToggleDepartment} />
          ) : (
            <RiArrowRightSLine onClick={handleToggleDepartment} />
          ))}
      </div>
      {!!category.filhos?.length && toggleDepartment && (
        <DepartmentNavigation
          onClose={onClose}
          categories={category.filhos}
          nivel={nivel + 1}
        />
      )}
    </li>
  );
};

export default NavigationItem;
