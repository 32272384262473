import styled from 'styled-components';

import { colors, metrics } from 'styles';

type ContainerProps = {
  backgroundColor: string;
};

export const Container = styled.div<ContainerProps>`
  padding: 2.4rem 3.6rem;
  display: none;
  background-color: ${colors.green_dark};

  /* background: ${props =>
    props.backgroundColor ? props.backgroundColor : 'transparent'}; */

  section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 2.4rem;

    img {
      max-width: 20rem;
      margin: 0 1.2rem;
    }

    .bag {
      color: #fff;
      position: relative;

      svg {
        width: 2.4rem;
        height: 2.4rem;
      }

      span {
        position: absolute;
        background: #e96232;
        color: ${colors.text_white};

        text-align: center;

        top: -1.2rem;
        left: 60%;
        padding: 0.2rem 0.8rem;
        border-radius: 50%;

        font-size: 1.4rem;
      }
    }
  }

  @media (max-width: 1200px) {
    display: block;
  }
`;

export const MenuSandwich = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;

  span {
    box-shadow: ${metrics.box_shadow};

    height: 0.4rem;
    width: 3.6rem;
    background: #fff;

    margin-top: 0.4rem;
  }
`;
