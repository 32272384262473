import React, { useMemo, useState } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FiShoppingBag } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import SideMenu from 'components/common/Modal/SideMenu';
import { useMiniCart } from 'hooks';
import { IState } from 'store';
import { ICartProps } from 'store/modules/cart';
import { IConfigProps } from 'store/modules/config';

import Search from '../Search';
import HeaderMenu from './HeaderMenu';
import { Container, MenuSandwich } from './styles';

const MiniCart = dynamic(
  () => import(/* webpackChunkName: "MiniCart" */ '../../MiniCart'),
);

function HeaderMobile() {
  const router = useRouter();
  const { dropMiniCart, dropped } = useMiniCart();
  const { totalItens } = useSelector<IState, ICartProps>(
    state => state.cart.cart,
  );
  const { config, categories, backgroundColorHeader } = useSelector<
    IState,
    IConfigProps
  >(state => state.config);
  const [sideMenuDropped, setSideMenuDropped] = useState(false);

  const categoriesSO = useMemo(() => {
    if (!categories.length) return [];
    return (
      categories.find(item => item.permalink === 'ultrafarma-pet')?.filhos ||
      []
    );
  }, [categories]);

  return (
    <Container backgroundColor={backgroundColorHeader}>
      <section>
        <MenuSandwich onClick={() => setSideMenuDropped(true)}>
          <span />
          <span />
          <span />
        </MenuSandwich>

        <img
          onClick={() => router.push('/')}
          loading="lazy"
          src={config.aparencia?.logo}
          alt={config.loja?.nome || ''}
        />

        <div className="bag" onClick={dropMiniCart}>
          <FiShoppingBag />
          <span>{totalItens || '0'}</span>
        </div>
      </section>

      <Search />

      {sideMenuDropped && (
        <SideMenu onClose={() => setSideMenuDropped(false)} left>
          <HeaderMenu
            onClose={() => setSideMenuDropped(false)}
            categories={categoriesSO || []}
          />
        </SideMenu>
      )}

      {dropped && <MiniCart onClose={dropMiniCart} />}
    </Container>
  );
}

export default HeaderMobile;
