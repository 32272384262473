import styled from 'styled-components';

import { colors } from 'styles';

export const Container = styled.div`
  margin-bottom: 1.2rem;
  border-bottom: 1px solid ${colors.gray};

  .product-details {
    display: flex;
    align-items: center;

    padding: 0 2.4rem 1.2rem;
    height: max-content;

    .img-container {
      width: 10rem;
      background: ${colors.background_card};
      border-radius: 0.8rem;

      img {
        width: 100%;
        border-radius: 0.8rem;
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      margin-left: 2.4rem;
      flex: 1;
      color: ${colors.text_black};

      h5 {
        text-transform: uppercase;
      }

      .price-is {
        color: ${colors.text_black};
        margin-bottom: 0.4rem;
        font-size: 1.4rem;
        margin-top: 0.8rem;
      }

      .price-for {
        color: ${colors.green_primary};
        display: flex;
        font-size: 1.8rem;
        font-weight: 400;

        align-items: flex-end;
      }

      div {
        display: flex;
        align-items: center;
        align-self: flex-end;
        margin-top: 0.4rem;

        div {
          display: flex;
          align-items: center;
          margin-right: 1.2rem;
          font-size: 1.4rem;

          color: ${colors.red_error};
          cursor: pointer;

          svg {
            margin-right: 0.8rem;
          }
        }

        .counter {
          background: ${colors.white};
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          border: 1px solid ${colors.gray};
          border-radius: 0.8rem;

          button {
            width: 3.6rem;
            height: 3.6rem;
            border: 0;
            background: transparent;
            font-size: 1.8rem;
            color: ${colors.green_primary};

            &:disabled {
              cursor: not-allowed;
              color: ${colors.gray_light};
            }
          }

          input {
            margin: 0 0.8rem;
            max-width: 4.2rem;
            text-align: center;
            border: 0;
          }
        }
      }
    }
  }

  .promotion-details {
    display: flex;
    margin: 0 0 1.8rem 1.8rem;

    .promotion-values {
      position: relative;
      z-index: 10;

      span {
        width: max-content;
        max-width: 16rem;
        background: ${colors.green_primary};
        padding: 0.8rem;
        border-radius: 0.4rem;
        font-size: 1.4rem;
        font-weight: 500;
        color: ${colors.text_white};
        margin-left: 1.2rem;

        opacity: 0;
        transition: opacity 0.4s;
        visibility: hidden;
        position: absolute;
        left: 100%;

        &::before {
          content: '';
          border-style: solid;
          border-color: transparent ${colors.green_primary};
          border-width: 0.6rem 0.6rem 0.6rem 0;
          top: 1.2rem;
          right: 100%;
          position: absolute;
        }
      }

    }


      &:hover span {
        opacity: 1;
        visibility: visible;
    }}
`;
