import styled from 'styled-components';

import { colors } from 'styles';

export const Container = styled.ul`
  list-style: none;
  li {
    border-bottom: 1px solid ${colors.gray};
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        flex: 1;
      }

      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
    ul {
      margin-left: 1.2rem;
    }
  }

  .navigation-item.departamento div {
    padding: 1.2rem 0 1.2rem 0 !important;
  }

  .categorie-item {
    a {
      display: flex;
    align-items: center;
    gap: 10px;

    svg {
      width: 10px;
      fill: var(--primary-color);
    }
    }

  }
`;
