import styled from 'styled-components';

import { colors } from 'styles';

export const Container = styled.div`
  text-align: center;
  font-size: 1.4rem;

  color: ${colors.text_gray};

  button {
    color: ${colors.text_gray};
    background: transparent;
    border: 0;
    font-size: 1.4rem;
    font-weight: 700;
    border-bottom: 1px solid ${colors.text_gray};
    transition: 0.3s ease-in-out;

    &:hover {
      color: ${colors.green_primary};
    }
  }
`;

export const Warning = styled.div`
  max-width: 50rem;

  font-size: 1.6rem;
  margin: 2.4rem 1.2rem 3.6rem;
  color: ${colors.green_primary};
  text-align: center;
  line-height: 2.4rem;

  p {
    background: ${colors.green_light2};
    padding: 1.2rem 2.4rem;
    border-radius: 8px;
    margin-top: 1.2rem;
  }
`;
