import styled from 'styled-components';

import { colors } from 'styles';

export const Footer = styled.footer`
  .informations-footer {
    background-color: #eeee;
    padding-top: 40px;
    padding-bottom: 45px;

    .container-global {
      display: flex;
      gap: 100px;
    }

    h5 {
      color: var(--primary-color);
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 10px;
      width: fit-content;
    }

    .links {
      display: flex;
      flex-direction: column;
      width: fit-content;
    }

    .link {
      font-size: 14px;
      font-weight: 600;
    }

    .link + .link {
      margin-top: 6px;
    }
  }

  .payment {
    margin-bottom: 30px;

    p {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    img {
      width: 40px;
      height: 30px;
      object-fit: contain;
    }

    img + img {
      margin-left: 5px;
    }
  }

  .certificados {
    margin-bottom: 30px;
  }

  .app {
    display: flex;
  }

  .flex-app {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  @media only screen and (max-width: 1400px) {
    .informations-footer {
      .container-global {
        gap: 80px;
        max-width: 1120px;
      }

      h5 {
        font-size: 14px;
        margin-bottom: 9px;
        min-width: 170px;
      }

      .link {
        font-size: 13px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .informations-footer {
      .container-global {
        gap: 65px;
        max-width: 1000px;
      }

      h5 {
        font-size: 13px;
        margin-bottom: 9px;
        min-width: 170px;
      }

      .link {
        font-size: 12px;
      }
    }

    .payment {
      p {
        font-size: 12px;
      }
    }

    .flex-app {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media only screen and (max-width: 1024px) {
    .informations-footer {
      .container-global {
        flex-wrap: wrap;
        max-width: 700px;
        gap: 50px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .informations-footer {
      .container-global {
        flex-wrap: wrap;
        max-width: 500px;
        gap: 30px;
      }
    }
  }
`;
