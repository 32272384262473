import styled from 'styled-components';

export const Container = styled.div`
  /* max-width: 1440px; */
  margin: 0 auto;
  position: relative;

  main {
    flex: 1;
    width: 100%;
    /* padding: 0 1.2rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
  }
`;
