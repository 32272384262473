import React, { MouseEvent, useCallback, useEffect, useState } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';
import { FiArrowLeft } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useSpring } from 'react-spring';

import icone03 from 'assets/icones-03.png';
import icone08 from 'assets/icones-08.png';
import Button from 'components/common/Button';
import { IState } from 'store';
import { ICartProps } from 'store/modules/cart';

import { ProductGiftMiniCart } from './ProductGiftMinitCart';
import ProductItemMiniCart from './ProductItemMiniCart';
import { ContainerWrapper, Container, Content, ListEmpty } from './styles';

interface IMiniCartProps {
  onClose(): void;
}

const MiniCart: React.FC<IMiniCartProps> = ({ onClose }) => {
  const [reverse, setReverse] = useState(false);
  const { push } = useRouter();
  const cart = useSelector<IState, ICartProps>(state => state.cart.cart);

  const miniCartTransition = useSpring({
    from: { right: '-120%', opacity: 0 },
    to: { right: '0%', opacity: 1 },
    reset: reverse,
    reverse,
  });

  const handleToggle = useCallback(() => {
    setReverse(state => !state);

    setTimeout(() => {
      onClose();
    }, 500);
  }, [onClose]);

  const handleControlPropagation = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    },
    [],
  );

  const handleGoToCart = useCallback(() => {
    handleToggle();
    push('/checkout/carrinho');
  }, [push, handleToggle]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100vh';

    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'initial';
    };
  }, []);

  return (
    <ContainerWrapper
      onClick={onClose}
      role="presentation"
      className="MINICART"
    >
      <Container style={miniCartTransition} onClick={handleControlPropagation}>
        <div className="header">
          <button type="button" className="button-back" onClick={handleToggle}>
            <FiArrowLeft /> Voltar
          </button>

          <span>
            <Image loading="lazy" src={icone03} alt="Carrinho" />{' '}
            {cart.totalItens} {cart.totalItens === 1 ? 'item' : 'items'}
          </span>

          <span>
            <Image loading="lazy" src={icone08} alt="Peso" /> Peso:{' '}
            {cart.pesoTotal}kg
          </span>
        </div>

        <Content>
          {!!cart.id && !cart.estaVazio ? (
            <>
              {cart.itens.map(item => (
                <ProductItemMiniCart key={item.codigo} item={item} />
              ))}

              {cart.brindes.map((item, index) => (
                <ProductGiftMiniCart key={`${item.sku}-${index}`} item={item} />
              ))}
            </>
          ) : (
            <ListEmpty>
              <p>Você ainda não tem produtos em sua cesta.</p>

              <Button onClick={onClose}>FAÇA JÁ SUA COMPRA</Button>
            </ListEmpty>
          )}
        </Content>

        {!!cart.id && !cart.estaVazio && (
          <div className="footer">
            <div className="total">
              <p>TOTAL</p>
              <p>{cart.totalSemFreteTexto}</p>
            </div>
            <div>
              <button type="button" onClick={handleToggle}>
                CONTINUAR COMPRANDO
              </button>
              <button type="button" onClick={handleGoToCart}>
                VER MINHA CESTA
              </button>
            </div>
          </div>
        )}
      </Container>
    </ContainerWrapper>
  );
};

export default MiniCart;
