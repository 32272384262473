import { animated } from 'react-spring';
import styled from 'styled-components';

import { colors } from 'styles';

export const ContainerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  height: 100%;
  width: 100%;
  z-index: 1000;

  background: transparent;
`;

export const Container = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 1px;

  min-width: 30rem;
  max-width: 90vw;
  width: 50rem;
  height: 100%;

  background: ${colors.background};
  border: 1px solid ${colors.gray};

  z-index: 999;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 10px 15px #414141;

  .header {
    padding: 2.4rem 1.2rem;

    display: flex;
    align-items: center;

    button {
      margin-right: auto;
      display: flex;
      align-items: center;
      background: transparent;
      border: 0;

      color: ${colors.text_gray};

      svg {
        margin-right: 0.8rem;
        width: 1.8rem;
        height: 1.8rem;
      }
    }

    span {
      display: flex;
      align-items: center;
      color: ${colors.text_gray};

      font-size: 1.4rem;

      & + span {
        margin-left: 2.4rem;
      }

      img {
        width: 1.6rem;
        margin-right: 0.8rem;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    width: 100%;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &.total {
        border-bottom: 1px solid ${colors.gray};
        border-top: 1px solid ${colors.gray};
        padding: 2.4rem;
      }

      & + div {
        margin-top: 1.2rem;
        padding: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      button {
        padding: 0.8rem 1.2rem;
        border-radius: 0.4rem;
        font-size: 1.2rem;
      }

      > button {
        background: transparent;
        border-color: ${colors.green_primary};
        border-style: solid;
        color: ${colors.green_primary};
      }

      button + button {
        background: ${colors.green_primary};
        color: ${colors.text_white};
        border-color: ${colors.green_primary};
        border-style: solid;
      }
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  display: block;
  margin-bottom: auto;
  overflow-y: scroll;

  ::-webkit-scrollbar-track {
    background-color: #f4f4f4;
  }

  scrollbar-track-color: #f4f4f4;
  ::-webkit-scrollbar {
    width: 0.6rem;
    background: #f4f4f4;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.green_primary};
    border-radius: 1rem;
  }

  scrollbar-base-color: ${colors.green_primary};
`;

export const ListEmpty = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.text_gray};
  padding: 0 3.6rem;

  button {
    margin-top: 2.4rem;
    max-width: 25rem;
  }
`;
