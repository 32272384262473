import React, { useCallback, useEffect, useState } from 'react';

import Image from 'next/image';
import { FiTrash2 } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

import emptyImage from 'assets/produto-sem-imagem.jpg';
import progressiveDiscountImg from 'assets/mini-cart-progressive-discount.svg';
import { useNotices } from 'hooks';
import {
  IProductCartItem,
  updateQuantityOfProductInCart,
  addProductToCart,
} from 'store/modules/cart';
import { getGACartItemsParams } from 'utils/gtag';

import { Container } from './styles';

interface IProductItemMiniCartProps {
  item: IProductCartItem;
}

const ProductItemMiniCart: React.FC<IProductItemMiniCartProps> = ({ item }) => {
  const dispatch = useDispatch();
  const { addNotices } = useNotices();
  const [quantity, setQuantity] = useState(item.quantidade);

  const handleDecrementItem = useCallback(() => {
    dispatch(
      updateQuantityOfProductInCart({
        sku: item.codigo,
        quantity: item.quantidade - 1,
        showAfterRequest: false,
        GA: getGACartItemsParams({ product: item, qty: 1, remove: true }),
      }),
    );
  }, [dispatch, item.codigo, item.quantidade]);

  const handleIncrementItem = useCallback(() => {
    dispatch(
      addProductToCart({
        sku: item.codigo,
        quantity: 1,
        showAfterRequest: false,
        GA: getGACartItemsParams({ product: item, qty: 1 }),
      }),
    );
  }, [dispatch, item.codigo]);

  const handleDeleteItem = useCallback(() => {
    addNotices({
      messages: [`Você deseja realmente excluir o item ${item.nome}`],
      type: 'warn',
      needConfirmation: true,
      action: () =>
        dispatch(
          updateQuantityOfProductInCart({
            sku: item.codigo,
            quantity: 0,
            showAfterRequest: false,
            GA: getGACartItemsParams({
              product: item,
              qty: item.quantidade,
              remove: true,
            }),
          }),
        ),
    });
  }, [addNotices, item.nome, item.codigo, dispatch]);

  const handleSetItemQuantity = useCallback(
    (quantityItems: Number) => {
      const quantityItemsNumber = Number(quantityItems);

      if (Number.isNaN(quantityItemsNumber)) {
        addNotices({
          messages: ['Informe um número válido'],
          type: 'allMessages',
        });
      } else if (quantityItemsNumber < 1) {
        setTimeout(() => {
          handleDeleteItem();
        }, 1500);
      } else {
        setQuantity(quantityItemsNumber);
        setTimeout(() => {
          dispatch(
            updateQuantityOfProductInCart({
              sku: item.codigo,
              quantity: quantityItemsNumber,
              showAfterRequest: false,
              GA: getGACartItemsParams({
                product: item,
                qty: Math.abs(item.quantidade - quantityItemsNumber),
                remove: item.quantidade > quantityItemsNumber,
              }),
            }),
          );
        }, 1500);
      }
    },
    [dispatch, addNotices, handleDeleteItem, item.codigo],
  );

  useEffect(() => {
    setQuantity(item.quantidade);
  }, [item]);

  return (
    <Container>
      <div className="product-details">
        <div className="img-container">
          <Image
            loading="lazy"
            src={item.imagem || emptyImage}
            alt={item.nome}
            width={100}
            height={100}
          />
        </div>

        <div className="description">
          <h5>{item.nome}</h5>

          {item.precoDeTexto && (
            <p className="price-is">De {item.precoDeTexto}</p>
          )}
          <p className="price-for">Por {item.precoPorTexto}</p>

          <div>
            <div onClick={handleDeleteItem}>
              <FiTrash2 /> Excluir
            </div>
            <span className="counter">
              <button
                type="button"
                disabled={item.quantidade <= item.quantidadeMinimaVenda}
                onClick={handleDecrementItem}
              >
                -
              </button>
              <input
                type="text"
                value={quantity}
                onChange={e => handleSetItemQuantity(Number(e.target.value))}
              />
              <button
                type="button"
                disabled={
                  !!item.quantidadeMaximaVenda &&
                  item.quantidade >= item.quantidadeMaximaVenda
                }
                onClick={handleIncrementItem}
              >
                +
              </button>
            </span>
          </div>
        </div>
      </div>

      {item.promocao !== null && (
        <div className="promotion-details">
          <Image src={progressiveDiscountImg} />
          <div className="promotion-values">
            <span>
              {item.promocaoProgressivaTexto.map(promoItem => (
                <p>{promoItem}</p>
              ))}
            </span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default ProductItemMiniCart;
