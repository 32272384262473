import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { loadCartData } from 'store/modules/cart';

import Footer from './Footer';
import { HeadeComponent } from './Header';
import { Container } from './styles';

const Layout: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCartData({ showMiniCart: false }));
  }, [dispatch]);

  return (
    <Container>
      <HeadeComponent />
      <main>{children}</main>
      <Footer />
    </Container>
  );
};

export default Layout;
