import styled, { css } from 'styled-components';

import { colors } from 'styles';

interface IContainerProps {
  isErrored: boolean;
  isFocused: boolean;
}

export const Container = styled.div<IContainerProps>`
  border-radius: 0.4em;
  border: 1px solid var(--primary-color);
  width: 100%;
  height: 3.8rem;
  background: ${colors.background_card};
  padding: 0 1.6rem;

  display: flex;
  align-items: center;
  position: relative;

  ${props =>
    props.isErrored &&
    css`
      border-color: ${colors.red_error};
    `}

  ${props =>
    props.isFocused &&
    css`
      color: ${colors.green_primary};
      border-color: ${colors.green_primary};
    `}

  input {
    background: transparent;
    flex: 1;
    width: 100%;
    font-size: 1.6rem;
    height: 4rem;
    border: 0;
    color: #303030;
    font-weight: 700;

    &::placeholder {
      color: #303030;
      font-size: 1.4rem;
    }
  }
`;
