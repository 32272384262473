import React, {
  FormEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';

import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { FiSearch } from 'react-icons/fi';

import { useLoading } from 'hooks';

import * as S from './styles';
import { GAEvent } from 'utils/gtag';

const Search: React.FC = () => {
  const router = useRouter();
  const { setLoadingOn, loading, setLoadingOff } = useLoading();
  const [search, setSearch] = useState(() => {
    const searchStorage = Cookies.get('@Pet:cookies:search');

    if (searchStorage) {
      return searchStorage;
    }
    return '';
  });

  const handleSearch = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      if (!search) return;

      setLoadingOn();

      GAEvent({ action: 'search', data: { search_term: search } });
      
      router.push(`/busca/${search}`);

      Cookies.set('@Pet:cookies:search', search);
    },
    [search, router, setLoadingOn],
  );

  useEffect(() => {
    if (router.pathname !== '/busca/[slug]') {
      Cookies.remove('@Pet:cookies:search');
      setSearch('');
    }
  }, [router.query.slug, router.pathname]);

  useEffect(() => {
    if (router.query.slug === search && loading) {
      setTimeout(() => {
        setLoadingOff();
      }, 200);
    }
  }, [router.query.slug, setLoadingOff, loading, search]);

  return (
    <S.Search onSubmit={handleSearch} className="search">
      <input
        type="text"
        value={search}
        onChange={e => setSearch(e.target.value)}
        placeholder="Digite o nome, a marca ou o princípio ativo do produto..."
      />
      <button type="submit">
        <FiSearch />
      </button>
    </S.Search>
  );
};

export default memo(Search);
