import React, { useCallback, useRef } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FaInstagram, FaFacebook, FaYoutube } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import * as Yup from 'yup';

import Button from 'components/common/Button';
import { ContainerGlobal } from 'components/common/container';
import { useNotices } from 'hooks';
import api from 'services/api';
import { IResponse } from 'store/modules/common-types';
import { colors } from 'styles';
import getValidationErrors from 'utils/getValidationErrors';

import Input from './Input';
import { Container } from './styles';

interface IFormData {
  name: string;
  email: string;
}

const NewsLetter: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addNotices } = useNotices();

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string()
            .max(200, 'Nome muito extenso.')
            .required('Campo obrigatório.'),
          email: Yup.string()
            .max(100, 'E-mail muito extenso.')
            .email('E-mail inválido')
            .required('Campo obrigatório.'),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.post<IResponse>('/newsletter', {
          email: data.email,
          nome: data.name,
        });

        if (!response.data.contemAvisos && !response.data.contemErros) {
          addNotices({
            messages: ['Seu e-mail foi cadastrado com sucesso!'],
            type: 'allMessages',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [addNotices],
  );
  return (
    <Container>
      <ContainerGlobal>
        <div className="mail">
          <FiMail color={colors.text_gray} size="24" />
          <p>Cadastre-se e receba nossas novidades por e-mail</p>
        </div>

        <Form onSubmit={handleSubmit} ref={formRef}>
          <Input
            name="name"
            placeholder="Nome"
            containerClassName="input-container"
            maxLength={200}
          />
          <Input
            name="email"
            placeholder="E-mail"
            containerClassName="input-container"
            maxLength={100}
          />
          <Button type="submit">Cadastrar</Button>
        </Form>


        <div className="medias">
          {/* <p>Siga-nos nas redes sociais</p>
          <a
            href="https://www.instagram.com/sidneyoliveiracomvoce/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram color={colors.green_primary} size="24" />
          </a>
          <a
            href="https://www.facebook.com/sidneyoliveiracomvoce"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook color={colors.green_primary} size="24" />
          </a>

          <a
            href="https://www.youtube.com/channel/UCzgNQjmKtwvmQf1__l11itg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube color={colors.green_primary} size="24" />
          </a> */}
        </div>

        <div></div>

        <div></div>
      </ContainerGlobal>
    </Container>
  );
};

export default NewsLetter;
