import { memo, useCallback, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import SelectWithOutForm from 'components/common/Input/SelectWithOutForm';
import Modal from 'components/common/Modal';
import { IState } from 'store';
import { setUF, IUFProps } from 'store/modules/config';
import { IUserProps } from 'store/modules/profile';
import UF from 'utils/getUF';
import { objectIsEmpty } from 'utils/objectEmpty';

import { Container, Warning } from './styles';

export function Region() {
  const dispatch = useDispatch();
  const [toggleModal, setToggleModal] = useState(false);
  const user = useSelector<IState, IUserProps>(state => state.profile.user);
  const ufSelected = useSelector<IState, IUFProps>(state => state.config.uf);

  const ufs = useMemo(() => {
    return UF;
  }, []);

  const handleToggleModal = useCallback(() => {
    setToggleModal(state => !state);
  }, []);

  const handleSelectState = useCallback(
    (uf: IUFProps) => {
      dispatch(setUF(uf));
      handleToggleModal();
    },
    [dispatch, handleToggleModal],
  );
  return (
    <>
      <Container>
        <p>
          *Você está vendo os preços de{' '}
          <strong>{ufSelected.value || 'SP'}</strong>.
        </p>
        <p>ATENÇÃO: Os preços podem variar por estado.</p>
      </Container>
{/* 
      {toggleModal && (
        <Modal onClose={handleToggleModal}>
          {!objectIsEmpty(user) && (
            <Warning>
              <h3>SELECIONE A CIDADE</h3>
              <p>
                <strong>Atenção!</strong> Você está visualizando os preços do
                Estado referente ao endereço principal de seu cadastro, caso
                mude a consulta para outra região, os produtos de seu carrinho
                permanecerão com o preço do seu endereço.
              </p>
            </Warning>
          )}
          <SelectWithOutForm
            handleChange={handleSelectState}
            value={ufSelected}
            options={ufs}
            maxMenuHeight={200}
            isSearchable
          />
        </Modal>
      )} */}
    </>
  );
}

export const MemoizedRegion = memo(Region);
